import styled from "styled-components";
import ProjectCard from "./ProjectCard";
import { useScroll, motion } from "framer-motion";
import { useRef } from "react";
import ProjectCardBig from "./ProjectCardBig";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: 100px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Title = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Dela Gothic One", sans-serif;
  color: var(--accentLight);
`;

const Description = styled.div`
  text-align: center;
  opacity: 0.7;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const List = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  width: 62vw;
  gap: 30px;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const Row = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export default function MyProjects({ containerRef }) {
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.4 1"],
  });

  const animationCards = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.3 1"],
  });

  return (
    <Container id="projects-section" ref={targetRef}>
      <motion.div style={{ scale: animation.scrollYProgress }}>
        <Top>
          <Title>Projects</Title>
          <Description>
            Here I some of my main personal projects, I didn't just create them
            for my portfolio, I created them because I needed them
          </Description>
        </Top>
      </motion.div>

      <motion.div style={{ scale: animationCards.scrollYProgress }}>
        <List>
          {/* <ProjectCardBig
            gradient="linear-gradient(183deg, rgb(24 115 49), rgb(0 22 14))"
            image="paratime.png"
            title="Paratime"
            tagline="A VsCode extension that turns programming into a social game."
            link="https://paratime.app"
            technologies="VsCode API, Node.js, Express, React, MongoDB"
            description="basically you install the VsCode extension and then you can see how many hours your friends have clocked vs how many hours you have clocked and on what project."
          /> */}

          <Row>
            <ProjectCard
              gradient="linear-gradient(183deg, rgb(24 115 49), rgb(0 22 14))"
              image="paratime.png"
              title="Paratime"
              tagline="A VsCode extension that turns programming into a social game."
              link="https://paratime.app"
              technologies="VsCode API, Node.js, Express, React, MongoDB"
              description="basically you install the VsCode extension and then you can see how many hours your friends have clocked vs how many hours you have clocked and on what project."
            />

            {/* <ProjectCard
              gradient="linear-gradient(1deg, rgb(12, 13, 14), rgb(97 62 26))"
              image="shortbooks.png"
              title="Shortbooks"
              tagline="Libary of shortbooks"
              link="https://shortbooks.app"
              technologies="Node.js, Express, React, MongoDB, MaterialUI"
              description="Shortbooks is a platform where you can write online short books. Short books help you learn things faster with easy-to-understand, structured concepts."
            /> */}
            <ProjectCard
              gradient="linear-gradient(187deg, rgb(90 118 235), rgb(0, 0, 0))"
              image="cottonchat2.jpg"
              title="CottonChat"
              link="https://cottonchat.com"
              tagline="A debate platform"
              technologies="Node.js, Express, React, MongoDB, MaterialUI"
              description="you share an opinion, idea, or question, it becomes a discussion. Each discussion can have multiple private chat rooms, and each chat room can have up to five members. Smaller groups foster deeper discussions.

In a chat room, members privately debate their ideas. At the end of the discussion, each member submits a conclusion, which is then made public, and votes are cast on the conclusions."
            />
          </Row>
        </List>
      </motion.div>
    </Container>
  );
}
