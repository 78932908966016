// LoadingOverlay.js
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const LoadingOverlay = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    const handleLoad = () => setIsLoading(false);
    // const handleBeforeUnload = () => setIsLoading(true);

    window.addEventListener("load", handleLoad);
    // window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("load", handleLoad);
      //   window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Overlay data-load={isLoading} isLoading={isLoading}>
      <LoadingBar />
    </Overlay>
  );
};

export default LoadingOverlay;

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  /* transition: all 0.25s ease-in-out; */

  ${({ isLoading }) => {
    if (!isLoading) {
      return `
            opacity:0;
            pointer-events:none;
        `;
    }
  }}
`;

const loadingAnimation = keyframes`
  0% { transform: scaleX(0); }
  50% { transform: scaleX(1); }
  100% { transform: scaleX(1); }
`;

const LoadingBar = styled.div`
  width: 38vw;
  height: 5px;
  background: rgb(52, 77, 219);
  border-radius: 20px;
  animation: ${loadingAnimation} 7s;
  transform-origin: left;
`;
