import styled from "styled-components";
import goToAuthScreen from "../../controllers/goToAuthScreen.js";
import { HiMenuAlt4 } from "react-icons/hi";
import { GrGoogle } from "react-icons/gr";
import { useContext, useRef } from "react";
import Context from "../../Context.js";
import BrandContainer from "../brand/BrandContainer.js";
import CustomButton from "../helperComponents/CustomButton.js";
import ContactMe from "./loggedOut/ContactMe.js";
import MyProjects from "./loggedOut/MyProjects.js";
import CompanyStat from "./loggedOut/CompanyStat.js";
import Testimonials from "./loggedOut/Testimonials.js";
import MySkills from "./loggedOut/MySkills.js";
import PrestigiousPartners from "./loggedOut/PrestigiousPartners.js";
import TalkToExpert from "./loggedOut/TalkToExpert.js";
import Footer from "./loggedOut/Footer.js";
import { heIL } from "@mui/x-date-pickers";
import { AiFillInstagram, AiOutlineInstagram } from "react-icons/ai";
import { FiGithub } from "react-icons/fi";
import { SiUpwork } from "react-icons/si";
import { FaGithub, FaLinkedin, FaSquareUpwork } from "react-icons/fa6";
import { ImLinkedin } from "react-icons/im";
import MyBlog from "./loggedOut/MyBlog.js";
import LoadingOverlay from "./LoadingOverlay.js";

const ParentContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 45px;
  font-family: "Dela Gothic One", sans-serif;
  color: var(--accentLight);

  @media (max-width: 900px) {
    font-size: 17px;
    text-align: center;
  }
`;
const MainButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    gap: 20px;
  }
`;

const Medium = styled.div`
  font-size: 19px;
  font-weight: 600;
  opacity: 0.5;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 900px) {
    width: 100%;
    font-size: 13px;
    text-align: center;
    line-height: 22px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
  align-items: flex-start;
  margin-top: 10px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;

let linkVariant = "minimal";

const HeroSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90vw;
  gap: 200px;
  margin-top: 40px;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 50px;
  }
`;

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 90px;
  width: 90vw;

  @media (max-width: 900px) {
    justify-content: center;
    margin-top: 30px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  padding: 5px 5px;
  border-radius: 10px;
  padding-left: 30px;
  height: 60px;
  background: var(--mainGradient);

  @media (max-width: 900px) {
    display: none;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  font-size: 30px;
  align-items: center;
`;
const Social = styled.a`
  color: var(--accentColor);
`;

const AnimatedHeroImage = styled.img`
  width: 700px;
  margin-right: -100px;

  @media (max-width: 900px) {
    width: 60%;
    margin: 0;
  }
`;

const mainButtonStyle = {
  background: "var(--mainGradient)",
  color: "#111",
  boxShadow: "rgb(0 0 0 / 14%) red",
};

const btnStyle = {
  height: "50px",
  color: "#111",
};

const btnStyle2 = {
  height: "50px",
  color: "var(--accentColor)",
  backgroundColor: "#000000",
};

export default function LoggedOutHomePage() {
  const containerRef = useRef();

  return (
    <ParentContainer id="scrollableMain" ref={containerRef}>
      <TopPart>
        <BrandContainer />

        <Links>
          <CustomButton
            variant={linkVariant}
            style={btnStyle}
            onClick={scrollTo("projects-section")}
          >
            Projects
          </CustomButton>
          <CustomButton
            variant={linkVariant}
            style={btnStyle}
            onClick={scrollTo("skills-section")}
          >
            Skills
          </CustomButton>
          <CustomButton
            variant={linkVariant}
            style={btnStyle}
            onClick={scrollTo("blog-section")}
          >
            Blogs
          </CustomButton>
          {/* <CustomButton variant={linkVariant} style={btnStyle} onClick={scrollTo("projects")}>
            Reviews
          </CustomButton> */}
          <CustomButton
            variant="filled"
            style={btnStyle2}
            onClick={scrollTo("contact-section", 1000)}
          >
            Get in touch
          </CustomButton>
        </Links>
      </TopPart>

      <Main>
        <HeroSection>
          <TextContainer>
            <Tagline>
              Full Stack Dev . 3D Artist . Game Dev . UI/UX Designer
            </Tagline>
            <Medium>
              I have created multiple products that people use on a day-to-day
              basis, such as Paratime.app, a VSCode extension that allows you to
              see how many hours your friends have coded compared to you, making
              programming social and fun. Thousand of people user paratime on a
              daily on a daily basis.
              <br /> <br />
              Recently, I developed CottonChat.com, which is a debate platform.
            </Medium>
            <br />
            <MainButtons>
              <CustomButton
                style={mainButtonStyle}
                height={"40px"}
                width={"200px"}
                onClick={scrollTo("contact-section", 1000)}
              >
                Get in touch
              </CustomButton>

              <SocialLinks>
                <Social
                  target="_blank"
                  href="https://www.linkedin.com/in/arnavzek/"
                >
                  <FaLinkedin />
                </Social>
                <Social
                  target="_blank"
                  href="https://www.instagram.com/arnavzek/"
                >
                  <AiFillInstagram />
                </Social>
                <Social target="_blank" href="https://github.com/arnavzek">
                  <FaGithub />
                </Social>
                <Social
                  target="_blank"
                  href="https://www.upwork.com/freelancers/~014746df0318f0509c"
                >
                  <FaSquareUpwork />
                </Social>
              </SocialLinks>
            </MainButtons>
          </TextContainer>
          <AnimatedHeroImage src="/hero-compressed.gif" />
        </HeroSection>
        {containerRef.current ? (
          <>
            <MyProjects containerRef={containerRef} />
            <MyBlog containerRef={containerRef} />
            <MySkills containerRef={containerRef} />
            <ContactMe containerRef={containerRef} />
            <Footer containerRef={containerRef} />
          </>
        ) : null}
      </Main>

      <LoadingOverlay />
    </ParentContainer>
  );

  function scrollTo(targetID, additionalScroll = 0) {
    return () => {
      const scrollableDiv = document.getElementById("scrollableMain");
      const targetElement = document.getElementById(targetID);

      // Calculate the position to scroll to
      const scrollPosition = targetElement.offsetTop - scrollableDiv.offsetTop;

      // Smoothly scroll the scrollable div to the target element
      scrollableDiv.scrollTo({
        top: scrollPosition + additionalScroll,
        behavior: "smooth", // This enables the smooth scrolling animation
      });
    };
  }
}
